<template>
  <div class="history-item">
    <div class="timestamp-container"><span>{{ getDateString() }}</span><font-awesome-icon class="timestamp-icon" :icon="['far', 'clock']"/></div>
    <ul class="history-detail">
      <template v-for="(event, index) in events">
        <li :key="index">
          <p v-html="$sanitize(event, { allowedTags: ['div', 'strong', 'ul', 'li'] })"/>
        </li>
      </template>
    </ul>
    <div class="author-container"><div class="author-name"><span>{{ author }}</span><font-awesome-icon class="author-icon" :icon="['far', 'user']"/></div></div>
  </div>
</template>

<script>
import Vue from 'vue';
import * as moment from 'moment-timezone';
moment.tz.setDefault('Etc/UTC');

export default Vue.extend({
  name: 'EnumHistoryDetailCellRenderer'
  , data() {
    return {
      events: null
      , timestamp: null
      , author: null
    }
  }
  , beforeMount() {
    this.events = this.params.data.events;
    this.author = this.params.data.person.name;
    this.timestamp = this.params.data.timestamp;
  }
  , computed: {
    
  }
  , methods: {
    refresh(params) {
      this.params = params;
      this.events.splice(0, this.events.length, ...this.params.data.events);
      return true;
    }
    , getDateString() {
      if(moment.locale() != this.$i18n.locale) {
        moment.locale(this.$i18n.locale);
      }
      return moment(this.timestamp).format('DD MMM YYYY HH:mm');
    }
  }
})
</script>

<style lang="scss" scoped>
.history-detail {
  list-style-type: disc;
  padding-left: 40px;
  white-space: normal;
  word-wrap: break-word;
  margin-bottom: 0;
}

.history-detail p {
  margin-bottom: 0;
  line-height: 20px;
}

.history-detail li {
  margin-bottom: 5px;
  line-height: 20px;
}

.timestamp-container {
  position: relative;
  margin-bottom: 10px;
}

.timestamp-container span {
  padding-left: 20px;
}

.timestamp-icon {
  position: absolute;
  left: 0;
  top: 6px;
}

.author-container {
  width: 100%;
  margin-top: 10px;
}


.author-name {
  position: relative;
  float: right;
}

.author-name span {
  padding-left: 20px;
}

.author-name .author-icon, .timestamp-icon {
  position: absolute;
  left: 0;
  top: 6px;
}

.history-item {
  margin: 0;
  padding: 10px 0;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 14px;
  word-break: break-word;
}
</style>